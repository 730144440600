@import "src/_mixin.scss";

.serviceSearchHeader {
  width: 100%;
  opacity: 1;
  z-index: 10;
  transition: opacity 0.2s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    min-height: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    transform: translateY(-100px);
    transition: opacity 0.2s, transform 0.2s;
    z-index: -1;
  }

  &_expanded {
    @include media('mobile') {
      @include border_on('bottom');
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 102;

      &::before {
        min-height: 100vh;
        z-index: -1;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &_hidden {
    opacity: 0;
    z-index: -1;

    @include media('mobile') {
      opacity: 1;
      z-index: 1;
    }
  }

  &__close {
    @include button_close(14px);
    display: none;
    position: absolute !important;
    top: 10px;
    right: 12px;
  }

  &_expanded &__close {
    @include media('mobile') {
      @include button_close(20px, $grey, 2px);
      display: block;
      top: 8px;
      right: 2px;
      padding: 20px;
    }
  }
}
